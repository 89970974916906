import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { IFeaturesService, IApplicationContextService } from './services/services';
import { Features, UserRoles } from './models/models';
import { AppConfig } from './models/AppConfig';
import { Subscription } from 'rxjs';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { CheckForUpdateService } from './services/checkForUpdateService';
import { ICurrentUserService } from './services/currentUserService/icurrentuser.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { MessageService } from 'primeng/api';
import { UserClient } from './services/nswag/nswagclient';
import { filter, map } from 'rxjs/operators';
import { IUserManagerService } from './services/iUserManager.service';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, ITelemetryPlugin, SeverityLevel } from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';
import { TelemetryService } from './services/telemetry.service';

const isIframe = window !== window.parent && !window.opener;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit, OnDestroy {
  isMenuCollapsed = false;
  subscriptions: Subscription[] = [];
  // routes: object[] = [];
  title = 'OpsViewer';
  constructor(
    private _featureService: IFeaturesService,
    private _currentUserService: ICurrentUserService,
    private _applicationContextService: IApplicationContextService,
    private _cdr: ChangeDetectorRef,
    private _updates: SwUpdate,
    private _checkForUpdateService: CheckForUpdateService,
    private broadcastService: MsalBroadcastService,
    private messageService: MessageService,
    private _userClient: UserClient,
    private _userManagerService: IUserManagerService,
    private _msalService: MsalService,
    private router: Router,
    private _telemetryService: TelemetryService
  ) {

    
    //log if page was refreshed
    let entries= performance.getEntriesByType("navigation");
    if (entries && entries.length > 0 && (entries[0] as PerformanceNavigationTiming).type  === 'reload') {
      this._telemetryService.trackTrace({message: `page was refreshed`, severityLevel: SeverityLevel.Information});
      this._telemetryService.trackEvent({name:"pagerefresh"});
    }

    let accounts = this._msalService.instance.getAllAccounts();
    let account = accounts.filter(a => a.environment.indexOf('opsviewer') > -1);
    if (account.length === 1) {
      this._msalService.instance.setActiveAccount(account[0]);
    } else if (accounts.length > 1) {
      this._msalService.instance.setActiveAccount(accounts[0]);//counldn't find opsviewer account, so just use the first one
    }
    this.checkForUpdates();
  }

  public checkForUpdates(): void {
    this.subscriptions.push(this._updates.versionUpdates.
      pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))).subscribe(evt => {
          this.promptUser();
        }));
  }

  private promptUser(): void {
    console.log('updating to new version');
    this._updates.activateUpdate().then((res) => {
      if (confirm("A new version is available. Would you like to load the new version?")) {
        document.location.reload();
      }
    });
  }

  isCompanyAdministrationEnabled = false;
  featuresReady = false;
  userReady = false;
  displayName: string;
  email: string;
  isEditorOrAdmin = false;
  showSpinner = false;
  showLongLoadingMessage = false;
  showRequestsBar = false;

  private _spinnerSubscription: Subscription;
  private _longRunningSubscription: Subscription;
  private _showRequestsBarSupscription: Subscription;

  ngOnInit() {

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe((c) => {
        // console.log(this.authService.instance.getAllAccounts());
        let accounts = this._msalService.instance.getAllAccounts();
        let opsaccount = accounts.filter(a => a.environment.indexOf('opsviewer') > -1);
        if (opsaccount.length === 0) {
          console.warn('no opsviewer account found');
          if (!isIframe) {
            this._msalService.instance.loginRedirect();
          }
          return; //try to remove null reference errors that occur when logging in.
        }
        if (!isIframe) {
          this.getFeatures();
          this.getUserDetails();
          this.listenForGlobalSpinnerUpdates();
          this.listenForRequestsBarUpdates();
        }
      });


    this.subscriptions.push(this.broadcastService.msalSubject$.subscribe((msg: EventMessage) => {
      //console.log(msg);
      if (msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        msg.eventType === EventType.LOGIN_FAILURE 
        || msg.eventType === EventType.LOGOUT_FAILURE
        || msg.eventType === EventType.SSO_SILENT_FAILURE
        || msg.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE
        ) {
        this._telemetryService.trackTrace({message: `msal event ${msg.eventType} ${msg.error.message} ${msg.error.stack} `, severityLevel: SeverityLevel.Verbose});
      } else if (msg.eventType !== EventType.LOGIN_SUCCESS) {
        //this._telemetryService.trackTrace({message: `msal event ${msg.eventType} `, severityLevel: SeverityLevel.Information});
      }
      
    }));

    this.subscriptions.push(this.broadcastService.inProgress$.subscribe((status: InteractionStatus) => {
      //this._telemetryService.trackTrace({message: `msal InteractionStatus ${status} `, severityLevel: SeverityLevel.Information});
    }));



  }

  ngOnDestroy() {
    this._spinnerSubscription.unsubscribe();
    this._longRunningSubscription.unsubscribe();
    this._showRequestsBarSupscription.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }



  getFeatures() {
    this._featureService.getEnabledFeatures().subscribe(
      results => {
        this.isCompanyAdministrationEnabled = results.some(r => r === Features.CompanyAdministration);
        this.featuresReady = true;
        //this.getUserDetails();
      },
      error => {
        console.error(error);
      }
    );
  }

  getUserDetails() {


    this.displayName = this._currentUserService.getUserEmail();
    this.email = this._currentUserService.getUserEmail();
    this.userReady = true;
    this.getUserRoles();

  }

  getUserRoles() {
    this._userManagerService.getIsEditorOrAdmin().subscribe(u => {
      this.isEditorOrAdmin = u;
    }, error => {
      console.error(error);
    });

  }



  onMenuCollapseEvent(isCollapsed) {
    this.isMenuCollapsed = isCollapsed;
  }

  listenForGlobalSpinnerUpdates() {
    this._spinnerSubscription = this._applicationContextService.showApplicationSpinnerObserver.subscribe(
      s => this.showSpinner = s);
    this._longRunningSubscription = this._applicationContextService.showLongRunningObserver.subscribe(s => this.showLongLoadingMessage = s);
  }

  listenForRequestsBarUpdates() {
    this._showRequestsBarSupscription = this._applicationContextService.showRequestsBarObserver.subscribe(
      s => {
        this.showRequestsBar = s;
        this._cdr.detectChanges();
      });
  }

}
