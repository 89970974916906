import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, isDevMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';


import { AppRoutes } from './app.routes';
import { AppComponent } from './app.component';

import { TopbarComponent } from './components/topbar/topbar.component';
import { MenuComponent } from './components/menu/menu.component';
import { CompanyListComponent } from './components/company/company-list/company-list.component';
import { CompanyDetailComponent } from './components/company/company-detail/company-detail.component';
import { JobListComponent } from './components/job/job-list/job-list.component';
import { PostJobAnalysisComponent } from './components/job/post-job-analysis/post-job-analysis.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { UserDetailComponent } from './components/user/user-detail/user-detail.component';
import { DeviceListComponent } from './components/device/device-list/device-list.component';
import { DeviceDetailComponent } from './components/device/device-detail/device-detail.component';
import { WorkbookComponent } from './components/workbook/workbook.component';
import { OfflineWorkbookComponent } from './components/offlineWorkbook/offlineWorkbook.component';
import { OfflineWorkbookDetailComponent } from './components/offlineWorkbook/offlineWorkbook-detail/offlineWorkbook-detail.component';
import { WorkbookConfigurationComponent } from './components/shared/workbook-configuration/workbook-configuration.component';
import { CalculationsComponent } from './components/shared/calculations/calculations.component';
import { SharedModule } from './shared.module';

import {
  ICompanyService, CompanyService, OfflineService,
  ICountyDataService, CountyDataService,
  IDistrictService, DistrictService,
  IUserService, UserService,
  IDeviceService, DeviceService,
  IWorkbookConfigurationService, WorkbookConfigurationService,
  IWorkbookLogService, WorkbookLogService,
  IFeaturesService, FeaturesService,
  ICompanyWorkbookConfigurationService, CompanyWorkbookConfigurationService,
  IJobWorkbookConfigurationService, JobWorkbookConfigurationService,
  ICompanyCalculationsService, CompanyCalculationsService,
  IReportService, ReportService,
  INewCompanyRequestService, NewCompanyRequestService,
  ICompanyAssociationRequestService, CompanyAssociationRequestService,
  ICalculatedFieldConfigurationService, CalculatedFieldConfigurationService,
  IJobLinkerService, JobLinkerService
} from './services/services';

import { NameTransform } from './pipes/nameTransform';
import { NameListTransform } from './pipes/nameListTransform';
import { MsalModule, MsalInterceptor, MsalService, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalGuard, MsalBroadcastService, MsalRedirectComponent } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
//import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';

import { SimulatorModule } from './simulator/simulator.module';

import { CanEditGuard } from './guards/canEdit.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CTWOTubingODTransform } from './pipes/ct-woTubingODTransform';

import { IWorkbookV2Service } from './services/iWorkbookV2.service';
import { WorkbookV2Service } from './services/workbookV2.service';
import { IOfflineService } from './services/iOffline.service';
import { IOfflineWorkbookService } from './services/iOfflineWorkbook.service';
import { OfflineworkbookService } from './services/offlineworkbook.service';
import { IBootstrapWorkbookForOfflineService } from './services/iBootstrapWorkbookForOffline.service';
import { BootstrapWorkbookForOfflineService } from './services/bootstrapWorkbookForOffline.service';
import { IImportPlugDataService } from './services/iimportPlugData.service';
import { ImportPlugDataService } from './services/importPlugData.service';
import { IOpsViewerMessagesService } from './services/iOpsViewerMessages.service';
import { OpsViewerMessagesService } from './services/opsViewerMessages.service';
import { IApplicationContextService, ApplicationContextService } from './services/services';

import { DeviceConfigurationComponent } from './components/shared/device-configuration/device-configuration.component';
import { AddressComponent } from './components/shared/address/address.component';
import { NewCompanyRequestComponent } from './components/shared/requests/new-company-request/new-company-request.component';
// tslint:disable-next-line:max-line-length
import { CompanyAssociationRequestComponent } from './components/shared/requests/company-association-request/company-association-request.component';
import { RequestComponent } from './components/request/request.component';
import { JobDetailsComponent } from './components/shared/jobs/job-details/job-details.component';
import { JobWellInformationComponent } from './components/shared/jobs/job-well-information/job-well-information.component';
import { JobFluidInformationComponent } from './components/shared/jobs/job-fluid-information/job-fluid-information.component';
import { JobPerformanceSummaryComponent } from './components/shared/jobs/job-performance-summary/job-performance-summary.component';
import { JobFluidSummaryComponent } from './components/shared/jobs/job-fluid-summary/job-fluid-summary.component';
import { VendorJobCommonComponent } from './components/shared/jobs/vendor-job-common/vendor-job-common.component';
import { VendorJobComponent } from './components/job/vendor-job/vendor-job.component';
import { ConsumerJobComponent } from './components/job/consumer-job/consumer-job.component';
import { VendorConsumerJobsComponent } from './components/job/vendor-consumer-jobs/vendor-consumer-jobs.component';
import { UserProfileComponent } from './components/shared/user-profile/user-profile.component';
import { NavigationTopBarComponent } from './components/navigation-top-bar/navigation-top-bar.component';
import { JobsRedirectComponent } from './components/jobs-redirect/jobs-redirect.component';
import { ContactInfoComponent } from './components/shared/contact-info/contact-info.component';
import { JobLinkerComponent } from './components/shared/job-linker/job-linker.component';
import { JobLinkerListComponent } from './components/shared/job-linker/job-linker-list/job-linker-list.component';
import { JobLinkerEditComponent } from './components/shared/job-linker/job-linker-edit/job-linker-edit.component';
import { JobLinkerAdminComponent } from './components/job-linker-admin/job-linker-admin.component';
import { CheckForUpdateService } from './services/checkForUpdateService';
import { TcpDeviceComponent } from './components/shared/tcp-device/tcp-device.component';
import { ICurrentUserService } from './services/currentUserService/icurrentuser.service';
import { CurrentUserService } from './services/currentUserService/currentuser.service';
import { AppConfig } from './models/AppConfig';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ConsumerJobClient, UserClient, IUserClient, VendorJobClient, JobTicketClient, DeviceClient } from './services/nswag/nswagclient';
import { JobCacheService } from './services/job-cache.service';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { TelemetryService } from './services/telemetry.service';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { TicketListComponent } from './components/ticket-system/ticket-list/ticket-list.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FilePopUpComponent } from './components/files/file-pop-up/file-pop-up.component';

export function loggerCallback(_logLevel: LogLevel, message: string) {
  try {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: AppConfig.GLOBAL_CONFIG.applicationInsightsKey,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackTrace({ message, severityLevel: SeverityLevel.Verbose });
    console.log(message);
  } catch (error) {
    console.warn(error);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  AppConfig.GLOBAL_CONFIG = JSON.parse(localStorage.getItem('config'));
  //console.warn(AppConfig.GLOBAL_CONFIG);
  let subtenant = AppConfig.GLOBAL_CONFIG.tenant.split(".")[0];
  return new PublicClientApplication({
    auth: {
      // clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6', // Prod enviroment. Uncomment to use. 
      clientId: AppConfig.GLOBAL_CONFIG.clientID, // PPE testing environment
      // authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
      authority: `https://${subtenant}.b2clogin.com/${subtenant}.onmicrosoft.com/b2c_1_newsignupsigin`, // PPE testing environment.
      knownAuthorities: [`${subtenant}.b2clogin.com`],
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  let subtenant = AppConfig.GLOBAL_CONFIG.tenant.split(".")[0];
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  //protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);
  //protectedResourceMap.set('/api/*', ['https://opsviewer.onmicrosoft.com/web/read']); //["openid", "profile"]
  protectedResourceMap.set('/api/features', null);
  protectedResourceMap.set('/api/*', ["openid", "profile", `https://${subtenant}.onmicrosoft.com/web/read`]); //, "read","offline_access"["openid", "profile"]

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: (msalService, _httpReq, originalAuthRequest) => {

      return {
        ...originalAuthRequest,
        loginHint: msalService.instance.getActiveAccount()?.username
      };
    }
  }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({ declarations: [
        AppComponent,
        TopbarComponent,
        MenuComponent,
        CompanyListComponent,
        CompanyDetailComponent,
        JobListComponent,
        PostJobAnalysisComponent,
        UserListComponent,
        UserDetailComponent,
        DeviceListComponent,
        DeviceDetailComponent,
        WorkbookComponent,
        OfflineWorkbookComponent,
        OfflineWorkbookDetailComponent,
        NameTransform,
        NameListTransform,
        CTWOTubingODTransform,
        WorkbookConfigurationComponent,
        CalculationsComponent,
        DeviceConfigurationComponent,
        AddressComponent,
        NewCompanyRequestComponent,
        CompanyAssociationRequestComponent,
        RequestComponent,
        JobDetailsComponent,
        JobWellInformationComponent,
        JobFluidInformationComponent,
        JobPerformanceSummaryComponent,
        JobFluidSummaryComponent,
        VendorJobCommonComponent,
        VendorJobComponent,
        ConsumerJobComponent,
        VendorConsumerJobsComponent,
        UserProfileComponent,
        NavigationTopBarComponent,
        JobsRedirectComponent,
        ContactInfoComponent,
        JobLinkerComponent,
        JobLinkerListComponent,
        JobLinkerEditComponent,
        JobLinkerAdminComponent,
        TcpDeviceComponent,
        FilePopUpComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [MsalModule,
        AppRoutes,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        TableModule,
        ButtonModule,
        InputTextModule,
        CalendarModule,
        DropdownModule,
        FieldsetModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        DialogModule,
        DynamicDialogModule,
        InputMaskModule,
        CheckboxModule,
        ChipsModule,
        SelectButtonModule,
        SharedModule,
        ConfirmDialogModule,
        TooltipModule,
        AccordionModule,
        ProgressSpinnerModule,
        OverlayPanelModule,
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        SimulatorModule,
        ToastModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
        { provide: ICompanyService, useClass: CompanyService },
        { provide: ICountyDataService, useClass: CountyDataService },
        { provide: IDistrictService, useClass: DistrictService },
        { provide: IUserService, useClass: UserService },
        { provide: IDeviceService, useClass: DeviceService },
        { provide: IWorkbookConfigurationService, useClass: WorkbookConfigurationService },
        { provide: IWorkbookLogService, useClass: WorkbookLogService },
        { provide: IFeaturesService, useClass: FeaturesService },
        { provide: ICompanyWorkbookConfigurationService, useClass: CompanyWorkbookConfigurationService },
        { provide: IJobWorkbookConfigurationService, useClass: JobWorkbookConfigurationService },
        { provide: IWorkbookV2Service, useClass: WorkbookV2Service },
        { provide: IOfflineService, useClass: OfflineService },
        { provide: IOfflineWorkbookService, useClass: OfflineworkbookService },
        { provide: IBootstrapWorkbookForOfflineService, useClass: BootstrapWorkbookForOfflineService },
        { provide: ICompanyCalculationsService, useClass: CompanyCalculationsService },
        { provide: IReportService, useClass: ReportService },
        { provide: IImportPlugDataService, useClass: ImportPlugDataService },
        { provide: INewCompanyRequestService, useClass: NewCompanyRequestService },
        { provide: ICompanyAssociationRequestService, useClass: CompanyAssociationRequestService },
        { provide: IOpsViewerMessagesService, useClass: OpsViewerMessagesService },
        { provide: "IConsumerJobClient", useClass: ConsumerJobClient },
        { provide: ICalculatedFieldConfigurationService, useClass: CalculatedFieldConfigurationService },
        { provide: IApplicationContextService, useClass: ApplicationContextService },
        { provide: IJobLinkerService, useClass: JobLinkerService },
        { provide: ICurrentUserService, useClass: CurrentUserService },
        { provide: "IJobTicketClient", useClass: JobTicketClient },
        { provide: UserClient, useClass: UserClient },
        { provide: DynamicDialogConfig, useClass: DynamicDialogConfig },
        { provide: "IVendorJobClient", useClass: VendorJobClient },
        CheckForUpdateService,
        DeviceClient,
        CanEditGuard,
        ConfirmationService,
        OfflineService,
        Title,
        VendorJobClient,
        ConsumerJobClient,
        JobCacheService,
        MessageService,
        DialogService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        TelemetryService,
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
